import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuButton = lazy(
  () => import("./menu-button-CD_vbvv9.js").then((module) => ({
    default: module.MenuButton
  }))
);
function MenuButton({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuButton, { ...props });
}
const useMenuButtonContent = () => {
  return useMemo(
    () => ({
      menuButton: {
        component: MenuButton
      }
    }),
    []
  );
};
export {
  LazyMenuButton as MenuButton,
  useMenuButtonContent
};
